import axios from "axios";
import { api } from "./config";

export default class API{
    static async getPaymentInfo(token) {
        return axios.get(api+'api/payments',{
            'headers': {
                'Authorization': 'Bearer '+token
            }  
        })
    }
    static async updatePaymentInfo(token, userId, data) {
        return axios.put(api+'api/payments/'+userId, data,{
            headers:{
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async getSubscriptions (token, subscriptionId) {
        return axios.get(api+'api/subscriptions/'+subscriptionId, {
            'headers': {
                'Authorization': 'Bearer '+token
            }  
        })
    }
    static async subscripitonIndex(token, filter, pagenumber) {
        return axios.get(api+'api/subscriptions?page='+pagenumber+
        '&category='+filter.category+
        '&subject='+filter.subject+
        '&level='+filter.level,{
            'headers': {
                'Authorization': 'Bearer '+token
            }      
        })
    }
    static async storeSubscripitons(token, data) {
        return axios.post(api+'api/subscriptions', data, 
        {'headers': {
            'Authorization': 'Bearer '+token
        }})
    } 


    static async cancelSubscripiton(token, id) {
        return axios.delete(api+'api/subscriptions/'+id,{
            headers:{
                'Authorization': 'Bearer '+token
            }
        })
    }
}