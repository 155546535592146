import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import NewObjectModel from "../../components/Models/newObjectModel";
import ResourceDetails from "../../screen/Resources/resourceDetails";
import { Button } from "react-bootstrap";

export default function AddResource (props) {
    const {
        resourceAdd,
        loadResources,
    } = props;
    const dispatch = useDispatch();
    const resourceInsert = async (data) => {
        //pull stats out here.
        await dispatch({type: 'SHOW_PRELOAD', payload: true});

        await resourceAdd(data).then(async (res) =>{
            await dispatch({ type: 'SET_CURRENT_PROGRESS', payload: [res.data.progressId]})
            await loadResources()
            await dispatch({ type: 'RESET_UPDATABLE'})
            await dispatch({ type: 'SET_NOTIFICATION', payload: {
                show: true,
                code: 300,
                message: 'Uploading resource to server.'
            }})
        }
        ).catch(()=>
            dispatch({ type: 'SET_NOTIFICATION', payload: {
            show: true,
            code: 500,
            message: 'Failed to upload resource.'
        }}))
        await dispatch({type: 'SHOW_PRELOAD', payload: false});
        await dispatch({type:'SHOW_LEFT_SLIDE', payload: false})
    }
    const setSlideInContent = () => {
        dispatch({type:'SHOW_LEFT_SLIDE', payload: true})
        dispatch({type:'LEFT_SLIDE_CONTENT', payload:
            ()=><NewObjectModel
                    model='resource'
                    itemType={'inventory'}
                    submit={resourceInsert}
                >
                    <ResourceDetails/>
                </NewObjectModel>
            
        })
    }
    return(
    resourceAdd&&
    <Button
        variant="primary"
        onClick={()=>setSlideInContent()}
    >
        <FontAwesomeIcon
            icon={faPlus}
        /> New inventory item 
    </Button>
    )
}