import axios from "axios";
import { api } from "./config";

export default class API{
    static async inventoryIndex (token, filter, pagenumber){
        const suffix = !filter.keyword?
        '&category='+filter.category+
        '&subject='+filter.subject+
        '&level='+filter.level
        :
        '&keyword='+filter.keyword

        return axios.get(api+'api/inventory?page='+pagenumber+suffix,
            {
                'headers': {
                    'Authorization': 'Bearer '+token
                }      
            })
    }
    static async publicInventoryIndex([], filter, pagenumber){
        return axios.get(api+'api/inventory?page='+pagenumber+
            '&category='+filter.category+
            '&subject='+filter.subject+
            '&level='+filter.level
        )
    }
    static async groupInventoryIndex(token, group, filter, pagenumber) {
        const suffix = !filter.keyword?
        '&category='+filter.category+
        '&subject='+filter.subject+
        '&level='+filter.level
        :
        '&keyword='+filter.keyword

        return axios.get(api+'api/groups/'+group+'/inventory?page='+pagenumber+suffix,
        {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })

    }
    static async inventoryShow(token, id) {
        return axios.get(api+'api/inventory/'+ id, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async publicInventoryShow(id) {
        return axios.get(api+'api/inventory/'+ id)
    }

}