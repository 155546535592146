import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";


export default function DateFilter (props) {
    const {
        className,
        dates,
        currentDate,
        setCurrentDate,
    } = props
    const timeToString = (date) => {
        return (
            new Intl.DateTimeFormat("en-US", {month: 'long'}).format(date)+' '+
            new Date(date).getDate()+', '+
            new Date(date).getFullYear()
        )
    }
    const tgtUser = useSelector(state=>state.tgtUser)
    useEffect(()=>{
        (!currentDate||dates.some(date=>date===currentDate))&&
        setCurrentDate(new Date(timeToString(dates[0])).getTime())
    },[dates, tgtUser])
    return (
    <Dropdown
        className={className}
        style={{zIndex:1040}}
    >
        <Dropdown.Toggle variant="outline-dark">
            {!currentDate?'Getting dates...':timeToString(currentDate)}
        </Dropdown.Toggle>

        <Dropdown.Menu className="drop-shadow overflow-auto" style={{maxHeight: 250}}>
        {[...new Set(dates.map(date=>timeToString(date)))].map((date, index)=>
            <Dropdown.Item
                key={index}
                onClick={()=>setCurrentDate(new Date(date).getTime())}
            >
                {date}
            </Dropdown.Item>
        )}
        </Dropdown.Menu>
    </Dropdown>
    )
}