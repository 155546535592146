import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, {persistor} from './store'
import {Elements} from '@stripe/react-stripe-js';
import { stripe_public_token } from './API/config';
import {loadStripe} from '@stripe/stripe-js';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);
const stripeInit = loadStripe(stripe_public_token);


root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Elements stripe={stripeInit}>
        <App />
      </Elements>
    </PersistGate>
  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
