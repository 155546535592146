import {CardElement} from '@stripe/react-stripe-js';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import PaymentsAPI from '../../../../API/payments';

export default function PaymentMethod (props) {
    const {
        card,
        setCard,
        justify,
        setTax
    } = props;
    const token = useSelector(state=>state.token);
    const [cards, setCards] = useState(['new']);
    useEffect(()=>{
        PaymentsAPI.getPaymentInfo(token)
        .then(response=>{
            setCards(response.data.cards[0]?[response.data.cards[0], 'new']:['new'])
            setCard(response.data.cards[0]??'new')
            setTax&&setTax(response.data.taxInfo)
        })
        .catch(err=>console.log(err))
    },[token])
    return (
        <div className={"row align-items-center justify-content-"+justify}>
            {cards.length>1&&<div className='px-2 col-auto mb-2'>
            <ButtonGroup>
                {cards.map((cardset, index)=>
                    <ToggleButton
                        key={index}
                        type="radio"
                        variant='outline-secondary'
                        checked={cardset === card}
                        onClick={()=>setCard(cardset)}
                    >
                        {cardset==='new'?'Switch': 'My current'} card
                    </ToggleButton>
                )}
            </ButtonGroup>
            </div>}
            <div 
                className={'text-start text-'+justify}
                style={{width: 450}}
            >
            {card!=='new'?
                <p className='m-0'>
                **** **** **** {card.card.last4} - {card.card.exp_month}/{card.card.exp_year.toString().slice(-2)}
                </p>
                :
                <CardElement
                    className="form-control login-input"
                />
            }
            </div>
        </div>
    )
}