import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/Table";
import BillingsAPI from "../../../../../API/payments";
import RowContainer from "../../../../components/Table/rowContainer";
import PaymentAPI from "../../../../../API/payments";
import SubscriptionDetails from "./subscriptionDetails";


export default function Subscription () {
    const token = useSelector(state=>state.token)
    const dispatch = useDispatch();
    const tableEntryTemplates =
    [
        {
            label:"",
            key:'',
            col:'auto',
        },
        {
            label:"Subscription start",
            key:'conditionalComponent',
            conditionalComponent: ({data})=>
            <>
                <p className="text-start m-0 pe-2">{
                    new Intl.DateTimeFormat("en-US").format(Number(data.created_at))
                }</p>
            </>,
            col:'',
        },
        {
            label:"Resources",
            key:'conditionalComponent',
            conditionalComponent: ({data})=>
            <>
                <div className="text-start m-0 ps-2">{
                    data.SupscribedInventoryItems.map((item, index)=>
                        <p key={index} className="m-0"><b className="m-0">{item.Title}</b></p>
                    )
                }</div>
            </>,
            col:'',
        },
        {
            label:"Prices",
            key:'conditionalComponent',
            conditionalComponent: ({data})=>
            <>
                <div className="text-center m-0 px-1">
                    <p className="m-0">${
                    data.SupscribedInventoryItems.map((item)=>
                        Number(data.recurring==='month'?
                            item.priceMonthly
                            :
                            item.priceYearly)*data.taxRate
                    ).reduce((a,b)=>a+b, 0).toFixed(2)}    
                    </p>
                </div>
            </>,
            col:'',
        },
        {
            label:"Recurring",
            key:'recurring',
            col:'2',
        },
        {
            label: 'Actions',
            key: 'actions',
            col:'2',
            actions:[
                {
                name:'View details',
                verb:'GET',
                icon: faEye,
                objectDetails:<SubscriptionDetails/>,
                execute: async ()=>{}
                },
                {
                name:(e)=>e.willCancel==='true'?"Unsubscribed":"Unsubscribe",
                verb:'DELETE',
                disabled: (e)=>e.willCancel==="true",
                icon: faTrash,
                description:"All related payment will be stopped at term end. Subscribed items can still be used in the meantime. Unsubscribed term cannot be undone.",
                execute: async (data)=>{
                    await PaymentAPI.cancelSubscripiton(token, data._id)
                    .then(async () =>
                        await dispatch({ type: 'SET_NOTIFICATION', payload: {
                            show: true,
                            code: 200,
                            message: "Subscription updated."
                        }})
                    ).catch(err=>
                        dispatch({ type: 'SET_NOTIFICATION', payload: {
                            show: true,
                            code: 404,
                            message: 'Fail to update subscription. Please contact support!'
                        }})
                    )
                }},
            ]

        },
    ]


return(
    <Table
        className="drop-shadow w-100 h-100"
        tableEntryTemplates={tableEntryTemplates}
    >
        <RowContainer
            target={'resource'}
            tableEntryTemplates={tableEntryTemplates}
            extractFilter={(e)=>e.filter(item=>item.name==='resources')[0].value}
            onLoad={(setEntries, filters)=>BillingsAPI.subscripitonIndex(token, filters, 1).then(res=>
                setEntries([
                    ...res.data.filter(section=>section.recurring==='month'),
                    ...res.data.filter(section=>section.recurring==='year')
                ])
            )}
        />
    </Table>
)
}