import jsPDF from "jspdf"
import { localDeployment, assetsSrc, api } from '../../../../API/config';


export default async function generatePdfFromImages(worksheetsArray, resourceId, category) {
    var pdf = await new jsPDF('p', 'in', 'letter');
        pdf.deletePage(1);
    var completeCount = 0;
    var imgUrl = await worksheetsArray.map(e=>(localDeployment?api:assetsSrc)+"vendors-upload/"+resourceId+'/'+e);
    for(const element of imgUrl) {
        var imgData = await getBase64Image(element, category, '/assets/static-banner-local.jpg');
        pdf.addPage();
        pdf.addImage(imgData, 0, 0, 8.5, 11);
        completeCount = completeCount + 1;
        if (completeCount === imgUrl.length) {
            var output = pdf.output('bloburl')
            window.open(output, '_blank');
    }}
    }
    async function addImgProcess(url) {
      const value = new Promise((resolve, reject) => {
      const img = new Image();  
        img.crossOrigin = 'Anonymous';
        img.onload = function() {
             resolve(img)
        };
        img.onerror = reject;
        img.src = url;
      })
      return value;
    }
    async function getBase64Image(url, cat, bannerUrl) {
      var mainWs = await addImgProcess(url);
      var UniversalBanner = await addImgProcess(bannerUrl);
      
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");

      canvas.width = mainWs.width;
      canvas.height = mainWs.height;
      
      await ctx.drawImage(mainWs, 0, 0);
      if(cat === 'Foundation') {
        var StaticRatios = UniversalBanner.width/UniversalBanner.height;
          await ctx.drawImage(UniversalBanner, canvas.width / 2 - canvas.width*0.88 / 2,
                                            canvas.height*0.05,
                                            canvas.width*0.88, 
                                            canvas.width*0.88/StaticRatios);
          }
          var dataURL = canvas.toDataURL("image/jpeg");
          return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

    }