import { useState } from "react"
import { InputGroup, Form, Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import API from "../../../API/accounts";

export default function Password () {

    const dispatch = useDispatch()
    const [assignPassword, setAssignPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();
    const [error, setError] = useState();

    const token = useSelector(state=>state.token)

    const updatePassword = () => {
        newPassword===confirmNewPassword?
        API.updateAccounts(token, {
            "oldPassword":assignPassword,
            "newPassword":newPassword
        }, null)
        .then(response=>{
            if(response.data.code===200){
                dispatch({ type: 'SET_NOTIFICATION', payload: {
                    show: true,
                    code: response.data.code,
                    message: response.data.message
                }})
            }else{
                dispatch({ type: 'SET_NOTIFICATION', payload: {
                    show: true,
                    code: response.data.code,
                    message: response.data.message
                }})
            }
        })
        .catch(err=>console.log(err))
        :
        setError('New password missmatch')

    }

    return (
    <div className="row justify-content-center align-items-center h-100">
        <div style={{width: 450}} className='bg-white p-4 drop-shadow rounded'>
            <h4 className="mb-4">Change password</h4>
            <InputGroup className="px-3">
                <Form.Control
                className="login-input"
                type="password"
                placeholder="Old password"
                value={assignPassword}
                onChange={e=>{
                    setError()
                    setAssignPassword(e.target.value)
                }}

                />
            </InputGroup>
            <InputGroup className="my-3 px-3">
                <Form.Control
                className="login-input"
                placeholder="New password"
                type="password"
                value={newPassword}
                onChange={e=>{
                    setError()
                    setNewPassword(e.target.value)
                }}
                />
            </InputGroup>
            <InputGroup className="px-3">
                <Form.Control
                className="login-input"
                placeholder="Re-type new password"
                type="password"
                value={confirmNewPassword}
                onChange={e=>{
                    setError()
                    setConfirmNewPassword(e.target.value)
                }}
                />
            </InputGroup>
            {error&&<p className="text-danger m-0">{error}</p>}
            <Button 
                className="mb-3 mt-4 px-5"
                onClick={()=>updatePassword()}
            >Update</Button>
        </div>
    </div>
    )
}