import { useEffect, useState } from "react";
import InventoryAPI from "../../../API/inventory";
import { useLocation, useNavigate } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { lazyLoad } from "../../components/Table/lazyLoad";

export default function SearchResult () {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = useSelector(state=>state.token);
    const role = useSelector(state=>state.role);
    const srcGroup = useSelector(state=>state.srcGroup);
    const [pageLoad, setPageLoad] = useState(1);
    const [searchGroupResult, setSearchGroupResult] = useState([]);
    const [prevInnerHeight, setPrevInnerHeight] = useState(0);
    const [searchResult, setSearchResult] = useState([]);
    const keyword = location.search.replace('?keyword=','')
    useEffect(()=>{
        dispatch({type:'SHOW_PRELOAD', payload: true});
        dispatch({ type: 'SET_SCREEN', payload:'resources/inventory'})
        getAssetIndex()
        setPrevInnerHeight(0)
    }, [keyword])
    const getAssetIndex = async () => {
        dispatch({type:'SHOW_PRELOAD', payload: true});
        await InventoryAPI.groupInventoryIndex(token, srcGroup._id, {
                keyword:keyword,
            }, pageLoad)
            .then(response=>{
                pageLoad===1?
                    setSearchGroupResult(response.data)
                    :
                    setSearchGroupResult([...searchGroupResult, response.data])
                dispatch({type:'SHOW_PRELOAD', payload: false});
            })
            .catch(err=>console.log(err))
        await InventoryAPI.inventoryIndex(token, {
                keyword:keyword,
            }, pageLoad)
            .then(response=>{
                pageLoad===1?
                    setSearchResult(response.data)
                    :
                    setSearchResult([...searchResult, response.data])
                dispatch({type:'SHOW_PRELOAD', payload: false});
            })
            .catch(err=>console.log(err))
    }
    const redirect = async (item) =>{
        await InventoryAPI.inventoryShow(token, item._id).then(async res=>{
            await dispatch({type: 'SET_BOOKMARK', payload: item});
            await dispatch({ type: 'SET_CURRENT_RESOURCE', payload: res.data})
            await dispatch({ type: 'SET_SCREEN', payload: role==='admin'?'inventory/resourceIndexBySection':'inventory/editTool'})
            await navigate(
                {
                    pathname: role==='admin'?'../resourceIndexBySection':'../editTool',
                    search: '?keyword='+keyword,
                }
            )
        })
    }
    return (
        <div 
            className="w-75 mx-auto h-100 py-4 overflow-y-scroll scrollbar-hide"
            onScroll={(e)=>{
                lazyLoad(e, setPageLoad, prevInnerHeight)&&setPrevInnerHeight(e.target.scrollHeight)
            }}
        >
        {[...searchResult, ...searchGroupResult].length>0?
        [...searchResult, ...searchGroupResult].map((item, index)=>
        <Accordion key={index} className="mt-2" defaultActiveKey={[index]} >
            <Accordion.Item 
                eventKey={index}
            >
                <Accordion.Header>
                    <p className="m-0">{item.Title}</p>
                </Accordion.Header>
                <Accordion.Body 
                    className="py-1 px-3"
                    style={{backgroundColor: 'rgba(0,0,0,0.05)'}}
                >
            {item.Chapters.map((chapter, cindex)=>
            <Accordion 
                key={cindex}
                defaultActiveKey={[cindex]}
                className="my-1"
            >
                <Accordion.Item 
                    className="bg-none"
                    key={cindex}
                    eventKey={cindex}
                >
                    <Accordion.Header>
                        <p className="m-0">{chapter.Label}</p>
                    </Accordion.Header>
                    <Accordion.Body
                        className="p-0"
                        style={{backgroundColor: 'rgba(0,0,0,0.05)'}}
                    >
                        {chapter.Navigations.map((navigation, sindex)=>
                            <div
                                key={sindex}
                                className="card border-0 w-100 text-start bg-none pointer"
                                onClick={()=>redirect(
                                    {
                                        'Chapter': cindex,
                                        'Section': sindex,
                                        'label':navigation.Label,
                                        '_id':item._id
                                    }
                                )}
                            >
                                <p className="m-0 px-2 py-3">
                                    {navigation.Label}
                                </p>
                            </div>
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            )}
            </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        )
        :
        <div className="w-100 d-flex align-items-center justify-content-center">
            <p>No result</p>
        </div>
        }
        </div>
    )
}
