import { Card, useAccordionButton, AccordionContext } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { useSelector } from "react-redux";

import TOCEditableEntry from "./TOCEditableEntry";


export default function ExpandChapterTrigger (props) {
    const { 
        children, 
        eventKey, 
        callback,
        navigate,
        updatePreSubmitTOCdata
    } = props

    const screen = useSelector(state=>state.screen);
    const bookmark = useSelector(state=>state.bookmark);
    const { activeEventKey } = useContext(AccordionContext);
    const execute = useAccordionButton(eventKey, () => callback && callback(eventKey));
    const isCurrentEventKey = activeEventKey === eventKey;
    const editingState = screen.indexOf('vendor')>=0;
    const ExpandIndicator = () => 
    <FontAwesomeIcon
        icon={isCurrentEventKey?faChevronUp:faChevronDown}
    />
return (
    <Card.Header 
        className="d-flex justify-content-between align-items-center pointer border-0 rounded-0"
        onClick={!editingState?execute:undefined}
    >

        {editingState?
            <TOCEditableEntry
                cindex={eventKey}
                index={0}
                label={children}
                isChapter
                navigate={navigate}
                updatePreSubmitTOCdata={updatePreSubmitTOCdata}
            />
            :
            <p className={"m-0 py-1 text-start "+(bookmark.Chapter===eventKey&&"text-danger")}>{children}</p>
        }
        <div 
            className={editingState?"bg-secondary text-white p-1 rounded me-3 hover":undefined}
            onClick={editingState?execute:undefined}
        >
            <ExpandIndicator/>
        </div>
    </Card.Header>
);
}