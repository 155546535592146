import { Button} from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";

export default function DeleteObjectModel (props) {
    const {
        submit,
        model,
        reload,
        description
    } = props
    const dispatch = useDispatch();
    const submitData = {
        "resource":useSelector(state=>state.currentResource),
        "group":useSelector(state=>state.tgtGroup),
        "user":useSelector(state=>state.tgtUser)
    }[model]
    return(
    <>
        <div 
            className="text-white text-start p-3 bg-secondary col order-1"
        >
            <p className="m-0">Are you sure you want to proceed? This cannot be undone.</p>
        </div>
        <div className="p-2 text-start col-12 order-3 text-dark secondary-font">
            {description}
        </div>
        <div className="d-flex justify-content-end px-3 my-2 col-12 order-4">
            <Button
                className="me-1"
                onClick={async ()=>
                        await submit(submitData).then(async ()=>{
                        await reload&&reload()
                        await dispatch({ type: 'SHOW_LEFT_SLIDE', payload: false })
                    })
                }
            >
                    Confirm
            </Button>
            <Button
                variant="danger"
                onClick={()=>
                    dispatch({ type: 'SHOW_LEFT_SLIDE', payload: false })
                }
            >
                    Cancel
            </Button>
        </div>
    </>
    )
}