import axios from "axios";
import { api } from "./config";

export default class API{

    static async authenticate(data) {
        return axios.post(api+'api/accounts', data)
    }

    static async getAccount(token) {
        return axios.get(api+'api/accounts', {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async updateAccounts(token, updateObjects, userId) {
        return axios.put(api+'api/accounts/'+userId, updateObjects, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
}