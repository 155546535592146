
export default function ShowObjectModel (props) {
    const {
        children,
    } = props
    return(
        <>
        <div 
            className="text-white text-start p-3 bg-secondary col order-1"
        >
            <p className="m-0">Details</p>
        </div>
        <div className="col-12 order-3">
            <div className="py-2">
                {children}
            </div>
        </div>
        </>
    )
}
