import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import API from "../../../../../API/payments"
import Table from "../../../../components/Table"
import RowContainer from "../../../../components/Table/rowContainer"

export default function SubscriptionDetails () {
    const token = useSelector(state=>state.token)
    const currentSubscription = useSelector(state=>state.currentResource)
    const [invoices, setInvoices] = useState([]);
    const entryTemplate = [
        {
            label:"",
            key:'',
            col:'auto',
        },
        {
            label:"Period",
            key:'conditionalComponent',
            conditionalComponent: ({data})=>
            <>
                <p className="text-start m-0 pe-2">{
                    new Intl.DateTimeFormat("en-US", {
                        month: 'long',
                        year: 'numeric',
                        day: 'numeric'
                    }).format(Number(data.period)*1000)
                }</p>
            </>,
            col:'8',
        },
        {
            label:"Status",
            key:'conditionalComponent',
            conditionalComponent: ({data})=>
                <div className="w-100 text-center">
                    <p className="m-0">
                        {data.paid?"Paid":"Pending"}
                    </p>
                </div>,
            col:'',
        },
    ]
    useEffect(()=>{
        currentSubscription._id&&API.getSubscriptions(token, currentSubscription._id).then(res=>{
            setInvoices(res.data)
        })
    },[currentSubscription])
   
    return(
    currentSubscription._id&&
    <div className="text-dark text-start">
        <div>
            <p className="">Status: <b className={"m-0 "+ (currentSubscription.willCancel==="true"?"text-danger":"text-success")}>
                {currentSubscription.willCancel==="true"?'Cancel at term end':'Active'}
            </b></p>
        </div>
        <div className="mb-2">
            <p className="m-0">Subscribed resources</p>
            <ul>
                {currentSubscription.SupscribedInventoryItems.map((item, index)=>
                <li key={index}><p className="m-0">${((currentSubscription.recurring==='month'?item.priceMonthly:item.priceYearly)*currentSubscription.taxRate).toFixed(2)} - <b>{item.Title}</b></p></li>
                )}
            </ul>
        </div>
        <div>
            <p className="m-0">Invoices</p>
            <Table
                className="w-100"
                style={{height:"650px"}}
                tableEntryTemplates={entryTemplate}
            >
                <RowContainer
                    target={'resource'}
                    tableEntryTemplates={entryTemplate}
                    onLoad={(setEntries)=>API.getSubscriptions(token, currentSubscription._id).then(res=>
                        setEntries([
                            currentSubscription.willCancel!=="true"&&{
                                "period": (res.data[0].period+(currentSubscription.recurring==='month'?2629743:31556926)),
                                "paid": false,
                            },
                            ...res.data
                        ])
                    )}
                />
            </Table>
        </div>
    </div>
    )
}