import { Button, Form, InputGroup } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FilterContainer from './filters/'
import ResourcesFilter from './filters/resourcesFilters'

export default function Searchbar (props) {
    const {
        className,
        type,
        toResultPage
    } = props
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const filters = useSelector(state=>state.filters)
    const screen = useSelector(state=>state.screen)
    const [keywordData, setKeywordData] = useState([]);
    const returnSearchIndex = async () => {
        await navigate(
            {
                pathname: `./SearchResult`,
                search: '?keyword='+keywordData,
            }
        )
    }

    const setFilters = (type, item) => {
        let updateFilter = filters;
        updateFilter = [
            ...updateFilter.filter(items=>items.name!==type),
            {
                name: type,
                value: item
            }
        ]
        dispatch({type:'SET_FILTERS', payload: updateFilter})
    }
    useEffect(()=>{
        location.pathname !== './SearchResult'&&setKeywordData([])
        setKeywordData(location.search?location.search.replace('?keyword=',''):[])
    }, [location])
    useEffect(()=>{
        screen.indexOf('groups')>=0&&type!=='resources'&&dispatch({type:'RESET_FILTERS'})
    },[screen, type])
    return (
    <div className={className}>
        <InputGroup
            className='rounded ps-2 d-flex align-items-center'
            style={{backgroundColor:'rgba(0,0,0,0.1)'}}
        >
            {
            type.indexOf('vendor')>=0||
            type.indexOf('resources')>=0&&type.indexOf('upload')<0&&
            location.pathname.indexOf("SearchResult")<0?
            <FilterContainer
                filters={filters}
            >
                {(type.indexOf('resources')>=0||type.indexOf('vendor')>=0)&&
                    <ResourcesFilter
                        filters={filters.filter(items=>items.name==='resources')[0].value}
                        setFilters={setFilters}
                    />
                }
            </FilterContainer>
            :
            <FontAwesomeIcon
                className='text-secondary'
                icon={faMagnifyingGlass}
            />
            }
            <Form.Control
                className="border-0 bg-none h-100"
                type="text"
                value={
                toResultPage?
                    keywordData
                    :
                    filters.filter(filter=>filter.name===type)[0]?
                        filters.filter(filter=>filter.name===type)[0].value.input
                        :
                        []
                }
                placeholder={
                    toResultPage?
                    "Enter keywords to search. Try 'fractions' or 'integers'..."
                    :
                    "Enter keywords to filter"
                }
                onChange={(e)=>
                {
                toResultPage?
                    setKeywordData(e.target.value.toLowerCase())
                    :
                    setFilters(type, {
                        ...filters.filter(filter=>filter.name===type)[0].value,
                        input: e.target.value.toLowerCase()
                    })
                }}
                onKeyDown={e=>{
                    e.keyCode === 13&&toResultPage&&
                        returnSearchIndex()
                    }
                }
            />
            {keywordData.length>0&&
                <FontAwesomeIcon 
                    className='me-4 pointer text-secondary'
                    icon={faTimes}
                    onClick={()=>{
                        setKeywordData([])
                        dispatch({type:'RESET_FILTERS'})
                    }}
                />
            }

            {type.indexOf('inventory')>=0&&toResultPage&&
            <Button 
                variant='secondary'
                onClick={()=>returnSearchIndex()}    
            >
                <FontAwesomeIcon 
                    className='mx-2'
                    icon={faSearch}
                />
            </Button>}
        </InputGroup>
    </div>
    )
}