import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import { localDeployment } from '../../../API/config'

import ResourcesNavigation from './resourcesNavigation'
import AssignButton from './assign'
import EditButton from './edit'


export default function SubNavActionButtons (props) {
    const {
        token,
        location,
        screen,
        allowPrint
    } = props

    const role = useSelector(state=>state.role)
    const keyword = location.search.replace('?keyword=','')
    return (
        <>
        {(screen.indexOf('editTool')>=0||
        screen.indexOf('resourceIndexBySection')>=0||
        location.pathname.indexOf('SearchResult')>=0)&&
        <>
        <div className='d-flex align-items-center'>
            <div className='row align-items-center'>
                <Button
                    className='col-auto lighter-hover'
                    variant='link'
                >
                    {screen.indexOf('assignment')>=0?
                    <Link
                        to={role==='admin'?'resources/assignment':'resources'}
                        className='text-dark'
                    >
                    <FontAwesomeIcon
                        className='me-2'
                        icon={faArrowLeft}
                    />
                    Assignment list
                    </Link>
                    :
                    screen.indexOf('uploads')>=0?
                    <Link
                        to={'resources/upload'}
                        className='text-dark'
                    >
                    <FontAwesomeIcon
                        className='me-2'
                        icon={faArrowLeft}
                    />
                    Uploads list
                    </Link>
                    :
                    screen.indexOf('resources')<0&&keyword?
                    <Link
                        to={{
                            pathname: `./SearchResult`,
                            search: '?keyword='+keyword,
                        }}
                        className='text-dark'
                    >
                    <FontAwesomeIcon
                        className='me-2'
                        icon={faArrowLeft}
                    />
                    Search result
                    </Link>
                    :
                    screen.indexOf('vendor')>=0?
                    <Link
                        to={'resources/vendor'}
                        className='text-dark'
                    >
                    <FontAwesomeIcon
                        className='me-2'
                        icon={faArrowLeft}
                    />
                    Vendor inventory
                    </Link>
                    :
                    <Link
                        to={'resources'}
                        className='text-dark'
                    >
                    <FontAwesomeIcon
                        className='me-2'
                        icon={faArrowLeft}
                    />
                    Inventory items
                    </Link>

                    }
                </Button>
            {screen.indexOf('resourceIndexBySection')<0&&
            screen.indexOf('assignment')<0&&
            screen.indexOf('uploads')<0&&
            location.pathname.indexOf('SearchResult')<0&&
            <ResourcesNavigation/>
            }
            </div>
        </div>

        {((screen.indexOf('assignment')<0&&location.pathname.indexOf('SearchResult')<0))&&!allowPrint&&role==='admin'&&screen.indexOf('vendor')<0&&!localDeployment&&
            <div className='d-flex align-items-center me-0 ms-auto'>
                <AssignButton
                    active={!(screen.indexOf('editTool')>=0&&role==='admin')}
                    prevScreen={screen.indexOf('uploads')>=0?'uploads':'inventory'}
                    token={token}
                />
                <EditButton
                    active={screen.indexOf('editTool')>=0&&role==='admin'}
                    prevScreen={screen.indexOf('uploads')>=0?'uploads':'inventory'}
                    token={token}
                />
            </div>
            }
        </>
        }
        </>
    )

}