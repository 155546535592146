import { localDeployment } from "../../../../API/config";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import {useStripe, useElements} from '@stripe/react-stripe-js';

import Banner from "./banner";
import Subscription from "./subscriptions";
import UserDetails from "../../Users/userDetails";
import { useEffect, useState } from "react";
import UsersAPI from "../../../../API/users";
import AccountsAPI from "../../../../API/accounts";
import PaymentMethod from "./paymentMethod";

export default function Index () {
    const dispatch = useDispatch();
    const elements = useElements();
    const stripe = useStripe();
    const token = useSelector(state=>state.token)
    const role = useSelector(state=>state.role)
    const myInfo = useSelector(state=>state.myInfo)
    const tgtUser = useSelector(state=>state.tgtUser)
    const [card, setCard] = useState('new')

    const submit = (card) => {
        dispatch({type:'SHOW_PRELOAD', payload: true});
        AccountsAPI.updateAccounts(token, {...tgtUser, card: card.token?card.token.id:null}, myInfo._id).then(async userResponse=>{
            AccountsAPI.authenticate({token: token})
            .then(async accountResponse=>{
                await dispatch({type:'SET_TOKEN', payload: accountResponse.data});
                await dispatch({type:"SET_MY_INFO", payload: {...myInfo, ...userResponse.data[0]} })
                await dispatch({type:'SHOW_PRELOAD', payload: false});
                await dispatch({type:'SET_NOTIFICATION', payload: {
                    show: true,
                    code: 200,
                    message: "Personal info updated."
                }})
            })
            .catch(()=>{
            dispatch({type:'SHOW_PRELOAD', payload: false});
            dispatch({ type: 'SET_NOTIFICATION', payload: {
                show: true,
                code: 400,
                message: "Failed to update personal info."
            }})
            })
        })
    }
    const keys = [
        {
            title: "General",
            content: 
            <div className="p-3 h-100">  
                {!tgtUser._id?
                <p>Retriving information...</p>
                :
                <div className="d-flex flex-column justify-content-between h-100">
                    <div>
                    
                        <h5 className="prime-font text-start">Personal info</h5>
                        <UserDetails/>
                        {myInfo.stripeId&&
                        <div className="my-2">
                            <h5 className="prime-font text-start">Payment method</h5>
                            <PaymentMethod
                                card={card}
                                setCard={setCard}
                            />
                        </div>
                        }
                    </div>
                    <div>
                        <Button onClick={async ()=>submit(
                            card==='new'?
                            await stripe.createToken(elements.getElement("card"))
                            :
                            card
                        )}>Apply changes</Button>
                    </div>
                </div>
                }  
            </div>
        },
        {
            title: "Banner",
            restriction: role!=="admin",
            content: <Banner/>
        },
        {
            title: "Subscription",
            restriction: (localDeployment||myInfo.admin==='1'),
            content: <Subscription/>
        }
    ]
    useEffect(()=>{
        UsersAPI.userShow(token, myInfo._id).then(res=>{
            dispatch({type:'SET_TGT_USER', payload: res.data})
        })
    },[token])
    return (
    <div 
        className="row align-items-center justify-content-center w-100 h-100 p-0"
    >
        <div
            className="bg-white drop-shadow rounded row p-0 overflow-hidden"
            style={{height:'80%', width:'80%', zIndex:100}}
        >
        <Tab.Container
            defaultActiveKey={keys[0].title}
            className="h-100"
        >
        <Row
            className="p-0 h-100"
        >
            <Col className="col-auto py-2 bg-secondary" >
            <Nav variant="pills" className="flex-column">
                {keys.map((key, index)=>
                !key.restriction&&<Nav.Item key={index}>
                    <Nav.Link eventKey={key.title} className="py-1 text-start">{key.title}</Nav.Link>
                </Nav.Item>
                )}
            </Nav>
            </Col>
            <Col className="h-100 p-0">
            <Tab.Content className="h-100 overflow-hidden">
                {keys.map((key, index)=>!key.restriction&&<Tab.Pane key={index} className="h-100" eventKey={key.title}>{key.content}</Tab.Pane>)}
            </Tab.Content>
            </Col>
        </Row>
        </Tab.Container>
        </div>
    </div>
    )
}