
export const localDeployment = false;

export const api = localDeployment?'http://192.168.10.200:8080/':'https://api.epentutor.com/';
// export const api = 'https://api.epentutor.com/';
// export const api = 'http://localhost:8000/';
export const assetsSrc= 'https://d3i7bhlv78zipy.cloudfront.net/';
export const websocket = 'wss://free.blr2.piesocket.com/v3/';
export const socketApiKey = '?api_key=YTKlKNF1hYigYqfIOT5NWm1ZcNDa7T5PPux3o7FS&';
export const maxFileSize = localDeployment?500000000:95000000

export const stripe_public_token = 'pk_test_51Nw7CYCpRaTGAh8L4kxNSFaLogcGJaCon727r1yQerHxGIM36rzCCTW7UIupGK8QkeXkdaZgZEplRV4l9eVkngSg00C0qZQa8f';